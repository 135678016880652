<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col>
                <v-alert v-if="errorMessage" dismissible type="error">
                    {{ errorMessage }}
                </v-alert>
                <v-card v-show="scannerVisible">
                    <v-card-title>
                        <v-icon large left color="primary"> mdi-qrcode </v-icon>
                        Scan a QR Code</v-card-title
                    >
                    <v-card-subtitle>
                        Use your device's camera to scan a QR Code.
                    </v-card-subtitle>
                    <v-card-text>
                        <qrcode-stream @init="onInit" @decode="onDecode" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: {
        QrcodeStream,
    },
    data() {
        return {
            scannerVisible: false,
            errorMessage: '',
        }
    },
    methods: {
        async onInit(promise) {
            this.scannerVisible = false
            try {
                const { capabilities } = await promise
                console.debug('QR Code scanner initialised', capabilities)
                this.scannerVisible = true
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.errorMessage =
                        'Permission to use the camera was denied'
                } else if (error.name === 'NotFoundError') {
                    this.errorMessage = 'No suitable camera was found'
                } else if (error.name === 'NotSupportedError') {
                    this.errorMessage = 'Use of the camera is not supported'
                } else if (error.name === 'NotReadableError') {
                    this.errorMessage = 'Camera already in use'
                } else if (error.name === 'OverconstrainedError') {
                    this.errorMessage = 'Unable to use camera'
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.errorMessage = 'Use of the camera is not supported'
                } else {
                    this.errorMessage = error.name
                }
            }
        },
        onDecode(decodedString) {
            const location = window.location
            const url = new URL(decodedString)
            if (url.hostname === location.hostname) {
                // This unfortunate hack was required to cater for a situation where QR codes were
                // issued that resolved to URLs with double forward slashes at the start of the pathname.
                // This string replacement ensures that there is 1 and only 1 forward slash at the beginning
                // of the pathname.
                const pathname = url.pathname.replace(/^\/*/, '/')
                this.$router.push(`${pathname}${url.search}`)
            } else {
                this.errorMessage = 'Invalid QR Code'
            }
        },
        onDragOver(isDraggingOver) {
            this.dragover = isDraggingOver
        },
    },
}
</script>

<style scoped></style>
